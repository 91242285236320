.segment-full {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.two-slots {
  display: flex;
  justify-content: space-between;
}


ion-progress-bar {
  --background: #026a8f;
  --progress-background: #98025b;
}

.link {
  cursor: pointer;
}

.ion-item-select-help {
  --min-height: 0px !important;

  ion-label {
    margin: 0 !important;

  }
}


.sheet-modal {
  --border-radius: 12px;
  --backdrop-opacity: 1;
  overflow: hidden;
}

.ion-fs-12 {
  font-size: 12px;
}

.hover {
  cursor: pointer;
}
