ion-button.ifoam-button {
  --background-activated: linear-gradient(90deg, #98025b 0%, #0377a1 60%, #026a8f 100%);
}

ion-button.ifoam-button::part(native) {
  background: radial-gradient(
      circle at -10% -10%,
      #ec008c 11%,
      #0097ce 58%,
      #0097ce 100%
  );
  animation: pulsateBackground 8s infinite ease-in-out;

  color: white;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

// lets make reversed version of BG
ion-button.ifoam-button.reverse::part(native) {
  background: radial-gradient(
      circle at -10% -10%,
      #0097ce 11%,
      #0097ce 58%,
      #ec008c 100%
  );
  animation: pulsateBackground 9s infinite ease-in-out;
}

ion-button.ifoam-button.fast-animation::part(native) {
  animation: pulsateBackground 3s infinite ease-in-out;
}

@keyframes pulsateBackground {
  0% {
    background-size: 100%;
  }
  50% {
    background-size: 150%;
  }
  100% {
    background-size: 100%;
  }
}
